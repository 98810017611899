<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-dialog
      v-model="dialog.active"
      persistent
      width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="value"
          v-on="on"
          readonly
          :class="`${classes}`"
          :disabled="disabled"
        />
      </template>
      <v-time-picker
        v-if="dialog.active"
        v-model="time"
        format="24hr"
        full-width
      >
        <v-spacer />
        <v-btn
          icon
          color="error"
          @click="onDeleteTime"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn
          icon
          color="default"
          @click="dialog.active = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <v-btn
          icon
          color="primary"
          @click="onSaveTime"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTime',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      time: '00:00',
      dialog: {
        active: false
      }
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone, '')
      return value
    }
  },
  created() {
    const builder = this.data.ChecklistBuilder
    const index = this.checklist.index
    const value = builder.getCellValue(index, this.cell, this.clone, '')
    this.time = value || builder.getTime(new Date())
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.value) {
            return
          }
          this.save(result.value)
        })
      }
    },
    onSaveTime() {
      this.save(this.time || '')
    },
    onDeleteTime(event) {
      this.save('')
    },
    save(value) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = this.value
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
      this.dialog.active = false
    }
  }
}
</script>
